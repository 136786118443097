body{
    background-color: rgb(221, 245, 210);
}
#myBox{
    border:2px solid rgb(53, 4, 4);
}
.btn {
    width: 13rem;
    margin-bottom: 1rem;
}
::selection{
    background-color: rgba(8, 8, 41, 0.979);
    color: aliceblue;
}
.alert{
    
    border: 2px solid rgb(2, 1, 43);
    width: 100%;
    text-align: center;
}
textarea{
    box-shadow: 4px 4px rgb(44, 235, 53);
    
}
.accordion{
    padding: 2rem;
}
